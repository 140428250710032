import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Notification, NotificationType } from './notification';
import { NotificationService } from './notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationListComponent implements OnInit, OnDestroy {
  notifications: Notification[] = [];
  private subscription: Subscription;
  types = NotificationType;

  constructor(private notifier: NotificationService) {}

  private addNotification(notification: Notification) {
    this.notifications.push(notification);

    if (notification.timeout > 0) {
      const intervalId = setInterval(() => {
        notification.timeLeftPercent -= 1600 / notification.timeout;

        if (notification.timeLeftPercent < 0) {
          clearInterval(intervalId);
          this.close(notification);
        }
      }, 16); // 60fps = (1000 / 60)
    }
  }

  ngOnInit() {
    this.subscription = this.notifier
      .getObservable()
      .subscribe((notification) => this.addNotification(notification));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close(notification: Notification) {
    this.notifications = this.notifications.filter((notif) => notif.id !== notification.id);
  }
}
