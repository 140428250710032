import { Component, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent implements OnDestroy {
  @Input() type: 'POSITIVE' | 'NEGATIVE';

  @Output() closeModal = new EventEmitter();
  @Output() send = new EventEmitter<string>();

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'modal-open');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
