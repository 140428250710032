import { Component, ViewEncapsulation } from '@angular/core';
import { Constants, Role } from 'src/app/shared/constants';
import { RolesService } from 'src/app/shared/services/roles-service.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AvatarComponent {
  constructor(private rolesService: RolesService) {}

  getDominantRole(): Role {
    const dominantRole = this.rolesService.getDominantRole();

    if (this.checkIfUserIsOnlyFromProduction() && dominantRole === Role.COMPETENCE_CENTER) {
      return Role.INSPECTOR;
    }

    return dominantRole;
  }

  private checkIfUserIsOnlyFromProduction(): boolean {
    return (
      this.rolesService.getBrandsOfUser().length === 1 &&
      this.rolesService.getBrandsOfUser()[0] === Constants.BRAND_PRODUCTION
    );
  }
}
