import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Constants, Role } from 'src/app/shared/constants';
import { RoleChange } from 'src/app/shared/models/mapped-roles.model';
import { RolesService } from 'src/app/shared/services/roles-service.service';

@Component({
  selector: 'app-form-aware-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormAwareAvatarComponent implements OnInit {
  private selectedBrand: string;
  private selectedRole: Role;

  constructor(private rolesService: RolesService) {}

  ngOnInit() {
    this.rolesService.onRoleChange.subscribe((roleChange: RoleChange) => {
      this.selectedBrand = roleChange.brand;
      this.selectedRole = roleChange.role;
    });
  }

  getDominantRole(): Role {
    if (this.checkIfProductionSelected() && this.selectedRole === Role.COMPETENCE_CENTER) {
      return Role.INSPECTOR;
    }

    return this.selectedRole;
  }

  private checkIfProductionSelected(): boolean {
    return this.selectedBrand === Constants.BRAND_PRODUCTION;
  }
}
