import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter, map } from 'rxjs/operators';
import { VWIDClaims } from 'src/app/shared/interfaces/jwt.interface';
import { UserService } from 'src/app/shared/services/user-service.service';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {
  public rawRoles: string[] = [];
  constructor(public oauthService: OAuthService, public userService: UserService) {}

  ngOnInit(): void {
    this.userService
      .getUser()
      .pipe(
        filter((user) => user !== null),
        map((user) => user.rawRoles)
      )
      .subscribe((rawRoles) => (this.rawRoles = rawRoles));
  }

  getIdClaims() {
    return this.oauthService.getIdentityClaims() as VWIDClaims;
  }

  getLastLogin() {
    return new Date(this.getIdClaims().auth_time * 1000).toLocaleString();
  }
}
