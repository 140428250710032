/** Control value should be matching regexp */
import { ValidatorFn } from 'ngx-typesafe-forms';

export function patternValidator(regexArray: Array<RegExp>): ValidatorFn<string> {
  return (control) => {
    let patternMatch = false;
    for (const regeExp of regexArray) {
      if (regeExp.test(control.value)) {
        patternMatch = true;
      }
    }
    return patternMatch || control.value == null || control.value.trim().length === 0
      ? null
      : { regexp: { value: control.value } };
  };
}
