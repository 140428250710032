import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Constants, Role } from 'src/app/shared/constants';
import { User } from '../../shared/models/user.model';
import { filter, take } from 'rxjs/operators';
import { UserService } from '../../shared/services/user-service.service';
import { environment } from '../../environment';
import { RolesService } from '../../shared/services/roles-service.service';
import { RoleChange } from '../../shared/models/mapped-roles.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  toggledMenu = false;
  public locale: any;
  public user: User;
  public ADMIN_COMPETENCE = [Role.ADMIN, Role.COMPETENCE_CENTER];
  public ALL_ROLES = Constants.ALL_ROLES;
  regions: Array<string>;
  public selectedRole: string;
  public selectedBrand: string;
  public Role = Role;
  public BRAND_PRODUCTION = Constants.BRAND_PRODUCTION;

  constructor(
    private userService: UserService,
    public router: Router,
    private translateService: TranslateService,
    public rolesService: RolesService
  ) {
    this.regions = environment.regions;
  }

  ngOnInit() {
    this.userService.login();
    this.userService
      .getUser()
      .pipe(
        filter((val) => val !== null),
        take(1)
      )
      .subscribe((x) => {
        this.user = x;
      });
    this.rolesService.onRoleChange.subscribe((event: RoleChange) => {
      this.selectedRole = event.role;
      this.selectedBrand = event.brand;
    });
  }

  isLoggedIn(): User {
    return this.user;
  }

  logout() {
    this.userService.logout();
  }

  changeLanguage(lang: string) {
    this.translateService.use(lang);
  }
}
