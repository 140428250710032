import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import { Newsletter, NewsletterLocalizedResponse } from '../interfaces/newsletter.interface';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
  constructor(private http: HttpClient) {}

  getNewsletters(language: string) {
    return this.http.get<NewsletterLocalizedResponse[]>(Constants.NEWSLETTERS_URL, {
      responseType: 'json',
      headers: {
        locale: language
      }
    });
  }
}
