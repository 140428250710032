<div class="notifications mar-large" style="pointer-events: none">
  <div
    *ngFor="let notification of notifications"
    class="notification notification--progress"
    [ngClass]="{
      'is-successful': notification.type === types.success,
      'is-uberdanger': notification.type === types.error,
      'is-caution': notification.type === types.warning,
      'is-default': notification.type === types.info
    }"
    style="width: 50%; pointer-events: auto"
  >
    <span
      class="notification__progress"
      style="opacity: 40%; width: {{ 100 - notification.timeLeftPercent }}%"
    ></span>
    <div class="notification__content">
      {{ notification.title | translate }}
      <br />
      {{ notification.message | translate }}
    </div>
    <a class="notification__close" (click)="close(notification)"></a>
  </div>
</div>
