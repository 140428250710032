/** Control value should exist in defined list */
import { ValidatorFn } from 'ngx-typesafe-forms';

export function arrayContainsValidators<T>(
  definedArray: Array<T>
): ValidatorFn<T> {
  return (control) => {
    return definedArray.includes(control.value)
      ? null
      : { notIncluded: { value: control.value } };
  };
}
