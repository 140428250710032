<div class="modal feedback-modal">
  <div class="modal__wrapper">
    <h1 class="modal__title">
      <i class="icon i-like" [ngClass]="{ dislike: type == 'NEGATIVE' }"></i>
      {{
        (type == 'POSITIVE'
          ? 'DIAGNOSIS.FEEDBACK.POSITIVE_TITLE'
          : 'DIAGNOSIS.FEEDBACK.NEGATIVE_TITLE'
        ) | translate
      }}
    </h1>
    <div class="modal__close" (click)="closeModal.emit()"></div>
    <div class="modal__content pad-vertical-medium">
      <label>{{ 'DIAGNOSIS.FEEDBACK.LABEL' | translate }}</label>
      <textarea class="feedback" #feedbackTextarea></textarea>
      <div class="flex flex--justify-end">
        <button (click)="send.emit(feedbackTextarea.value)" class="btn btn--primary mar-top-large">
          {{ 'DIAGNOSIS.FEEDBACK.SEND' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
