/** Control value should be exactly specified size or empty */
import { ValidatorFn } from 'ngx-typesafe-forms';

export function sizeValidator(size: number): ValidatorFn<string> {
  return (control) => {
    return control.value.length === size || control.value.length === 0
      ? null
      : { size: { value: control.value } };
  };
}
